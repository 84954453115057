.radio-button {
  display: flex;
}
.radio-button input[type="radio"] {
  --s: 18px; /* control the size */
  --c: #f39200; /* the active color */

  height: var(--s);
  aspect-ratio: 1;
  border: 2px solid #cdcdcd;
  padding: calc(var(--s) / 9);
  background: radial-gradient(farthest-side, var(--c) 94%, #0000) 50%/0 0
    no-repeat content-box;
  border-radius: 50%;
  outline-offset: calc(var(--s) / 10);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: inherit;
  transition: 0.2s;
}
.radio-button input[type="radio"]:checked {
  border-color: var(--c);
  background-size: 100% 100%;
}
