.week-range-picker {
  position: relative;
  z-index: 1;
}

.date-range-input {
  height: 45px;
  border: 1px solid #f0f2f4;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  color: #606c80;
  font-size: 14px;
  padding: 0px 20px;
  caret-color: transparent;
}

.week-range-picker .input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.week-range-picker .input-container img {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.week-range-picker .close-button {
  width: 15px;
}

/* New------------------------------------------------------- */

/* .week-range-picker .rdrDateRangeWrapper {
  position: absolute;
} */
.week-range-picker .rdrDateDisplayWrapper {
  display: none;
}

.week-range-picker .rdrMonthsVertical {
  flex-direction: unset;
}

.week-range-picker .rdrCalendarWrapper {
  width: 100%;
  border-radius: 10px;
  /* border: 1px solid #f1f1f1;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);

  font-family: "Inter", sans-serif;
  margin-top: 10px;
  padding-bottom: 15px; */
}

.rdrMonthAndYearPickers select:hover {
  background: none !important;
  background: url("../../assets/images/icons/Down-arrow.svg") no-repeat !important;
  background-position: right 5px center !important;
}

.week-range-picker .rdrMonthAndYearPickers select {
  color: #606c80;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px 10px 0px;
  background: url("../../assets/images/icons/Down-arrow.svg") no-repeat;
  background-position: right 5px center;
}

.week-range-picker .rdrNextPrevButton {
  margin: 0px;
  width: 18px;
  height: 18px;
  position: relative;
}

.week-range-picker .rdrMonthAndYearWrapper {
  padding: 10px 15px;
}

.week-range-picker .rdrPprevButton i {
  display: none;
}

.week-range-picker .rdrPprevButton::before {
  content: url("../../assets/images/icons/Calendar-left-arrow.svg");
  position: absolute;
  top: -1px;
  left: 6px;
}

.week-range-picker .rdrNextButton i {
  display: none;
}

.week-range-picker .rdrNextButton::before {
  content: url("../../assets/images/icons/Calendar-right-arrow.svg");
  position: absolute;
  top: -1px;
  right: 6px;
}

.week-range-picker .rdrDayDisabled {
  background-color: transparent !important;
  color: red !important;
}

.rdrDayDisabled .rdrInRange,
.rdrDayDisabled .rdrStartEdge,
.rdrDayDisabled .rdrEndEdge,
.rdrDayDisabled .rdrSelected,
.rdrDayDisabled .rdrDayStartPreview,
.rdrDayDisabled .rdrDayInPreview,
.rdrDayDisabled .rdrDayEndPreview {
  background: transparent !important;
  filter: none !important;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: transparent !important;
}

.rdrDayPassive .rdrDayNumber span {
  color: transparent !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #f39200 !important;
}

.rdrDayDisabled .rdrDayNumber span {
  color: #aeb9bf !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: transparent !important;
}

.date-picker-wrapper {
  background-color: white;
  position: absolute;
  width: 100%;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  margin-top: 10px;
  /* padding: px; */
}
.year-or-week-selection-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: rgb(239, 242, 247);
  font-size: 14px;
  border-radius: 5px 5px 0px 0px;
}

.year-picker-wrapper {
  background-color: white;
  position: absolute;
  width: 100%;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px 10px 0px 0px;
  font-family: "Inter", sans-serif;
  margin-top: 10px;
}

.year-or-week-selection-box .selection-button {
  width: 100%;
  text-align: center;
  color: #7d888d;
  background: white;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
  margin: 10px;
  padding: 3px;
  overflow: hidden;
  cursor: pointer;
}
.year-or-week-selection-box .active-selection-button {
  width: 100%;
  text-align: center;
  color: #7d888d;
  background: white;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid #f39200;
  margin: 10px;
  padding: 3px;
  overflow: hidden;
  cursor: pointer;
}

.rmdp-container .rmdp-wrapper {
  border-radius: 0px 0px 10px 10px;
}

/* .rmdp-container .rmdp-top-class {
 
} */
.rmdp-container .rmdp-ep-arrow {
  position: unset !important;
  display: none !important;
}

.rmdp-container div:nth-of-type(3) {
  margin-top: 49px;
  transform: none !important;
  width: 100%;
  border: 1px solid #f1f1f1;
  border-radius: 0px 0px 10px 10px;
  background-color: white;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  border-top: 0px;
}
.rmdp-shadow {
  box-shadow: none !important;
}
.rmdp-container .rmdp-calendar {
  border-top: 0px;
}

.rmdp-day {
  border: 0px !important;
  margin-top: 0px !important;
  box-shadow: none !important;
}

.rmdp-ym {
  border: 0px !important;
  margin-top: 0px !important;
  box-shadow: none !important;
}
