.input-conatiner {
  position: relative;
  display: flex;
  align-items: center;
}
.input-component-main .input-box {
  width: 100%;
  height: 45px;
  border: 1px solid #f0f2f4;
  border-radius: 5px;
  color: #606c80;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
}
.input-component-main .input-box:focus {
  outline: none;
}
.input-component-main .input-image {
  position: absolute;
  margin: 16px;
}
.input-component-main .rounded-input-box {
  padding-left: 40px !important;
  background-color: #f5f5f5;
  border-radius: 40px !important;
  width: 100%;
  height: 45px;
  border: 1px solid #f0f2f4;
  border-radius: 5px;
  color: #606c80;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
}
.input-component-main .rounded-input-box:focus {
  outline: none;
}
