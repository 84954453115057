.alert-component .modal-width {
  width: 330px;
  text-align: center;
}

.alert-component .alert-body {
  padding-bottom: 0px;
}

.alert-component .alert-body img {
  margin-top: 50px;
}

.alert-component .alert-body p {
  color: #000;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.alert-component .alert-body .detail-text {
  color: #606c80;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.alert-component .modal-footer {
  border-top: none;
  justify-content: space-between;
  padding: 25px;
}

.alert-component .alert-footer {
  border-top: none;
  justify-content: end;
  padding: 25px;
}

.alert-component .button-width {
  width: 135px;
}

.alert-component .continue-button-width {
  width: 100%;
}

.alert-component .alert-footer .button-component {
  width: 100%;
}

@media (max-width: 576px) {
  .alert-component .modal-width {
    width: auto;
  }
  .alert-component .modal-footer {
    justify-content: center;
  }
  .alert-component .margin-left {
    margin-left: 20px;
  }
}
