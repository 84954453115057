th.thead-th {
  border: 0;
}

td.tbody-td {
  border: 0;
}

.table-container table.table {
  padding-bottom: 0;
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.table-container th.thead-th {
  color: #323c4d;
  font-size: 12px;
  font-weight: 700;
}

.table-container tr.tbody-tr td {
  background: #fafbfc !important;
  padding: 5px 26px;
  font-size: 14px;
  vertical-align: middle;
  font-weight: 400;
  color: #606c80;
}

.table-container
  .ButtonGroup__root.btn-group
  button.btn.btn-primary:first-child {
  display: none;
}

.table-container
  .ButtonGroup__root.btn-group
  button.btn.btn-primary:last-child {
  display: none;
}

.table-container .ButtonGroup__root.btn-group {
  float: right;
  box-shadow: none;
}

.table-container button.btn.btn-primary {
  margin: 0 15px;
  font-weight: 500;
  border: 1px solid #bebebe;
  font-size: 12px;
  color: #2b2b2b;
  width: 40px;
  text-align: center;
  background: white;
  height: 40px;
  border-radius: 4px !important;
  padding: 0px;
}

.table-container button.btn.btn-primary:disabled {
  color: #f39200;
  border: 1px solid #f39200;
  background: #f9e6ca;
}

.table-container button.btn.btn-primary:nth-last-child(2) {
  text-indent: -9999px;
  background: url("../../assets/images/right-Arrow.png") no-repeat center !important;
  margin-right: 0px !important;
}

.table-container button.btn.btn-primary:nth-child(2) {
  text-indent: -9999px;
  background: url("../../assets/images/Left-arrow.png") no-repeat center !important;
}
.table-container tr td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.table-container tr td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.text-left-align .tbody-tr {
  text-align: left !important;
}

.table-container tr.thead-tr {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.table-container.multipleCount {
  flex-direction: row;
  display: flex;
}
.table-container.badge-block {
  background-color: #f9e6ca !important;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-left: 34px;
  justify-content: center;
  align-items: center;
  color: #f39200;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  overflow: unset;
}
.dateitem-text {
  color: #606c80;
  font-size: 14px;
  font-weight: 400;
}
.timeitem-text {
  color: #848d9b;
  font-size: 14px;
  font-weight: 400;
}

.table-container .command-logs-table thead tr th:first-child {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.table-container .command-logs-table thead tr th:last-child {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.table-container .text-center-align tbody tr {
  text-align: center;
}
.table-container .text-center-align tbody tr td {
  padding: 10px !important;
}

@media (max-width: 1200px) {
  .table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
