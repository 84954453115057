.device-control-page .page-title {
  color: #323c4d;
  font-size: 20px;
  font-weight: 700;

  margin-bottom: 30px;
  line-height: 20px;
}

.device-control-page .select-tab .nav-tabs {
  border-bottom: 1px solid #efefef;
  margin-bottom: 30px;
}

.device-control-page .select-tab .nav-link {
  padding: 17px 0px 10px 0px !important;
  margin-right: 40px;
  font-weight: 500;
  font-size: 13px;
  color: #606c80;
  text-transform: none !important;
}

.device-control-page .select-tab .nav-tabs .nav-link:hover {
  background: transparent;
  color: #2b2b2b;
}

.device-control-page .select-tab .nav-tabs .nav-link.active {
  border-bottom: 1px solid #f5a833;
  color: #2b2b2b;
}

.device-control-page .data-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 20px;
}

.device-control-page .top-border {
  border-top: 1px solid #efefef;
  margin-top: 20px;
  padding-top: 20px;
}

.device-control-page .selected-value-box {
  height: 45px;
  padding: 15px;
  border: 1px solid #f0f2f4;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  color: #606c80;
  font-size: 14px;
  font-weight: 400;
}

.device-control-page .selected-value-box img {
  cursor: pointer;
  margin-left: 25px;
}

.device-control-page .add-button {
  display: flex;
  align-items: center;
  height: 45px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.device-control-page .add-button img {
  margin-right: 10px;
}

.device-control-page .add-button .button-text {
  color: #f39200;
  font-size: 14px;
  font-weight: 500;
}

.device-control-page .add-modal-width {
  width: 340px !important;
}

.device-control-page .modal-button-width {
  width: 130px !important;
}

.device-control-page .notice-text {
  color: #606c80;
  font-size: 12px;
  font-weight: 400;
}

.device-control-page .border-red {
  border: 1px solid #f46f6f !important;
}

.device-control-page .error-text {
  color: #f46f6f;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0px;
  display: block;
}
.device-control-page .display-none {
  display: none;
}

.device-control-page .color-dot {
  height: 14px;
  width: 14px;
  margin-right: 10px;
  border-radius: 50%;
}

.device-control-page .quick-send-container .button-text {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
}

.device-control-page .input-label {
  color: #2b2b2b;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 5px;
}

.device-control-page .command-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.device-control-page .badge-modal .colored-button {
  display: none;
}

.device-control-page .badge-modal .badge-modal-width {
  width: 380px;
}

.device-control-page .badge-modal .modal-serial-number-box {
  border: 1px solid #f0f2f4;
  color: #606c80;
  font-size: 14px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  white-space: nowrap;
  flex-wrap: wrap;
  margin-top: 10px;
}

.device-control-page .firmware-modal-width {
  width: 380px;
}
.device-control-page .firware-detail-box {
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: rgba(246, 246, 246, 0.7);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
}

.device-control-page .firware-detail-box p {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 16px;
  color: #323c4d;
}
.device-control-page .firware-detail-box .detail-text span {
  color: #323c4d;
  font-weight: 300;
  font-size: 14px;
}

.device-control-page .input-label {
  color: #2b2b2b;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 5px;
}

.device-control-page .error {
  border: 1px solid #f46f6f !important;
}

.device-control-page .custom-dropdown.drop-error .ripple.ripple-surface {
  border: 1px solid #f46f6f !important;
}
.device-control-page .command-input input:disabled::placeholder {
  color: #bbbbbb !important;
}
.device-control-page .quick-send-container button:disabled {
  opacity: 0.3;
}

.device-control-page .enable.button-text {
  background-color: rgba(243, 146, 0, 0.2);
}

.device-control-page .location-label {
  font-size: 12px;
  font-weight: 400;
  color: #2b2b2b;
}

.device-control-page .dropdown-margin-top {
  margin-top: -10px;
}

.device-control-page .content-margin {
  margin-top: 10px;
  margin-bottom: 14px;
}

.device-control-page .lg-padding-right {
  padding-right: 0px !important;
}

.device-control-page .lg-padding-right:first-child {
  padding-left: 0px;
}

.device-control-page .quick-send-container .lg-padding-left {
  padding-left: 10px;
}

.device-control-page .section-top-margin {
  margin-top: 30px;
}
/* //toogle button css */
.device-control-page .toggle-Div {
  display: flex;
  justify-content: space-between !important;
}

.device-control-page .toggle-Switch {
  display: flex;
}
.device-control-page .toggle-Label {
  padding-left: 10px;
  color: #606c80;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 5px;
}

.device-control-page .pagination-button {
  margin-top: 30px;
}

.device-control-page .listing-box {
  max-height: 330px;
  margin-top: 5px;
  overflow-x: overlay;
}
.device-control-page .command-column-box {
  display: flex;
  flex-direction: row;
  max-width: 320px;
}
.device-control-page .command-column {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.device-control-page .badge-modal .command-badge-modal-width {
  width: 575px;
}
.device-control-page .command-listing-box {
  margin-top: 30px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: rgba(246, 246, 246, 0.7);
  padding: 10px;
}
.device-control-page .command-listing-box .item-command {
  font-size: 14px;
  word-wrap: break-word;
}
.device-control-page .location-div{
  display: flex;
  gap: 20px;
}
.device-control-page .inprogress-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  gap: 15px;
}
.device-control-page .inprogress-content .text{
 font-size: 16px;
 color: #323C4D;
 font-weight: 700;
}
@media (max-width: 1400px) {
  .device-control-page .quick-send-container .button-text {
    font-size: 13px;
  }
}

@media (max-width: 1200px) {
  .device-control-page .padding-right:first-child {
    padding-left: auto !important;
  }

  .device-control-page .padding-right {
    padding-right: auto !important;
  }
  .device-control-page .padding-left {
    padding-left: 0px;
  }

  .device-control-page .md-padding-left {
    padding-left: 0px;
  }
}

@media (max-width: 991px) {
  .device-control-page .md-padding-left {
    padding-left: 10px;
  }
}

@media (max-width: 768px) {
  .device-control-page .md-padding-left {
    padding-left: 0px !important;
  }
}

@media (max-width: 576px) {
  .device-control-page .add-modal-width {
    width: auto;
  }

  .device-control-page .firmware-modal-width {
    width: auto;
  }
}
