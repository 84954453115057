.page-not-found-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-not-found-page h3 {
  color: #323c4d;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 40px;
}

.page-not-found-page p {
  color: #606c80;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
