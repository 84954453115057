.selectData select {
  width: 100%;
  color: #141736;
  outline: none;
  background: transparent;
  height: 45px;
  border: 1px solid #f0f2f4;
  border-radius: 5px;
  color: #606c80;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
}

.selectData select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../../assets/images/arrown-down.svg");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 19px;
  padding-right: 2rem;
}
