.dups-user-device-list .table-container .tbody-td {
  padding: 16px;
}
.dups-user-device-list .phone-number-text {
  color: #323c4d;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 30px;
}
