
.alarm-modal .modal-width {
  width: 380px;
}
.alarm-modal .alarm-modal-container {
  border: 1px solid #f0f2f4;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  white-space: nowrap;
}
.alarm-modal .close-button{
  width: 100% !important;
  margin-right: 260px !important;
}