.device-alert-main-page .device-alert-tilte {
  font-size: 20px;
  font-weight: 700;
  color: #323c4d;
}
.device-alert-main-page .thead-tr {
  background-color: transparent !important;
}
.device-alert-main-page .table-container {
  margin-top: -50px;
}
.device-alert-main-page .device-heading-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
  margin-bottom: 20px;
}
.device-alert-main-page .pagination-button {
  margin-top: 30px;
}
.device-alert-main-page .pagination-Icon {
  margin-right: 5px;
}
.device-alert-main-page .settings-button {
  background-color: #ffffff !important;
  border: 1px solid #f0f2f4 !important;
  border-radius: 5px;
  padding: 10px;
  width: 50px;
}
.device-alert-main-page .download-export-box {
  display: flex;
  gap: 20px;
}
.device-alert-main-page .settings-button  img {
  margin-left: 0;
}

.device-alert-main-page .refresh-button-label {
  color: #f39200;
  text-align: left;
  display: flex;
  
}
.device-alert-main-page p { 
  margin-top: 10px;
  margin-bottom: 0px !important;
}
.arrow_box {
  background: white;
  border-radius: 8px;
  position: relative;
  color: #f39200;
  max-width: 480px;
  min-height: 35px;
  margin-right: 15px;
  padding-right: 26px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  padding-left: 17px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.arrow_box:after {
  content: "";
  position: absolute;
  right: -5px;
  top: 35%;
  background: white;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.device-alert-main-page .alert-selcted-date-range{
  color: #606C80;
  font-size: 12px;
  line-height: normal;
  font-weight: 600;
}
.device-alert-main-page .selcteddate-range{
  color:#606C80;
  font-size: 12px;
  line-height: normal;
  font-weight: 400;
  margin-top: 5px;
}