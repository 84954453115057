.card-types {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  /* margin-top: 5px; */
  align-items: center;
  border-radius: 12px;
  margin-right: 15px;
  position: relative;
  cursor: pointer;
}

.countOn-TypeCard {
  position: absolute;
  right: 20px;
  top: 21px;
  color: white;
  font-size: 18px;
  font-weight: 800;
}
.card-text {
  padding: 20px;
  color: white;
  font-size: 14px;
  font-weight: 500;
}
