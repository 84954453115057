.send-command-page .command-Text {
  color: #323c4d;
  font-weight: 600;
  font-size: 20px;
}
.send-command-page .quick-send-row {
  margin-bottom: 28px;
}
.send-command-page .quick-send-btton-row {
  display: flex;
}
.send-command-page .quick-send-input-row {
  display: flex;
}
.send-command-page .quick-send-input-row .button-text {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
}

.send-command-page .send-command-row {
  display: flex;
}
.send-command-page .input-btn {
  margin-top: 5px;
}
.send-command-page .start-btn-background-colur {
  background-color: #f9e6ca;
}
.send-command-page .stop-btn-background-colur {
  /* border-color: #976f33; */
  border-width: 1px;
  border-radius: 5px;
}
.send-command-page .timeInterval-btn-background-colur {
  border-color: #f39200;
  border-width: 1px;
  border-radius: 5px;
}
.send-command-page .updateFirmaware-btn-background-colur {
  border-color: #f39200;
  border-width: 1px;
  border-radius: 5px;
}

.send-command-page .input-add-command {
  width: calc(100% - 170px);
}
.send-command-page .quik-send-submit-btn {
  width: 100px;
}

.send-command-page .theme-card-component {
  width: 100%;
}

.send-command-page .card-component {
  width: 100%;
}

.send-command-page .text-quick-send-row {
  /* margin-top: 5px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.send-command-page .current-version-box {
  background-color: rgba(246, 246, 246, 0.7);
  border-radius: 5px;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-color: #f6f6f6;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 30px;
}

.send-command-page .version-text-box {
  line-height: 10px;
}

.send-command-page .text-current-target-version {
  color: #323c4d;
  font-size: 14px;
  font-weight: 300;
}
.send-command-page .text-version {
  color: #323c4d;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 14px;
}

.send-command-page .target-version-box {
  margin-top: 30px;
}

.send-command-page .text-target-version {
  color: #2b2b2b;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}
.send-command-page .upgrade-modal-width {
  width: 380px;
}
.send-command-page .custom-dropdown.drop-error .ripple.ripple-surface {
  border-color: #f46f6f !important;
  border-width: 1px solid !important;
}
.send-command-page .commad-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.send-command-page .error .ripple.ripple-surface {
  border-color: #f0f2f4 !important;
}
.send-command-page .error {
  border-color: #f46f6f !important;
  border-width: 1px solid !important;
}
.send-command-page .valid {
  border-color: #f0f2f4 !important;
}

.send-command-page .upgrade-modal-width .modal-footer {
  margin-top: 30px !important;
}

.send-command-page .enable.button-text {
  background-color: rgba(243, 146, 0, 0.2);
}

.send-command-page .lg-padding-right {
  padding-right: 0px !important;
}

.send-command-page .lg-padding-right:first-child {
  padding-left: 0px;
}

.send-command-page .quick-send-input-row .lg-padding-left {
  padding-left: 10px;
}

.send-command-page .pagination-button {
  margin-top: 30px;
}
.send-command-page .command-column-box {
  display: flex;
  flex-direction: row;
  max-width: 320px;
}
.send-command-page  .command-column {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.send-command-page .badge-modal .colored-button {
  display: none;
}
.send-command-page .badge-modal .command-badge-modal-width {
  width: 575px;
}
.send-command-page .command-listing-box {
  margin-top: 30px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: rgba(246, 246, 246, 0.7);
  padding: 10px;
}
.send-command-page .command-listing-box .item-command {
  font-size: 14px;
  word-wrap: break-word;
}
@media (max-width: 1400px) {
  .send-command-page .quick-send-input-row .button-text {
    font-size: 13px;
  }
}

@media (max-width: 1200px) {
  .send-command-page .padding-right:first-child {
    padding-left: auto !important;
  }

  .send-command-page .padding-right {
    padding-right: auto !important;
  }
  .send-command-page .padding-left {
    padding-left: 0px;
  }

  .send-command-page .md-padding-left {
    padding-left: 0px;
  }
}

@media (max-width: 991px) {
  .send-command-page .md-padding-left {
    padding-left: 10px;
  }
}

@media (max-width: 768px) {
  .send-command-page .md-padding-left {
    padding-left: 0px !important;
  }
}
