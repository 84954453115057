.create-user-page .createuser-Text {
  color: #323c4d;
  font-weight: 600;
  font-size: 20px;
}

.create-user-page .create-User-label {
  font-size: 12px;
  font-weight: 400;
  color: #2b2b2b;
}
.create-user-page .dropDown-Role {
  margin-top: 10px;
}
.create-user-page .create-user-password-div {
  margin-top: 40px;

}
.create-user-page .create-user-button-container {
  margin-top: 40px;
  display: flex;
  flex-direction: row-reverse;
}

.create-user-page .create-user-detail-div {
  margin-top: 30px;
}
.create-user-page .custom-dropdown.error .ripple.ripple-surface {
  border-color: #f46f6f !important;
  border-width: 1px solid !important;
}

.create-user-page .error {
  border-color: #f46f6f !important;
  border-width: 1px solid !important;
}
.create-user-page .valid {
  border-color: #f0f2f4 !important;
}

p.error-msg {
  font-size: 13px;
  margin-top: 2px;
}

