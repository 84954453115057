.topbar .navbar {
  border-bottom: 1px solid rgba(223, 223, 223, 0.5);
  box-shadow: none;
  background-color: white;
  height: 80px;
}

.topbar .topbar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
  align-items: center;

}

.topbar .profile-image-box {
  position: relative;
}

.topbar .profile-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #e6ebf2;
  overflow: hidden;
}

.topbar .user-status {
  width: 9px;
  height: 9px;
  background: #17e933;
  border-radius: 50%;
  position: absolute;
  right: 4px;
  top: 39px;
  z-index: 9;
}

.topbar .user-details {
  display: flex;
  align-items: center;
}

.topbar .username {
  color: #294566;
  font-size: 12px;
  font-weight: 700;
}

.topbar .user-role {
  color: #a3b6cc;
  font-weight: 500;
  font-size: 11px;
}

.topbar .user-details .dropdown button {
  background: none;
  padding: 0px;
  box-shadow: none;
}

.topbar .dropdown-toggle::after {
  display: none;

}

.topbar .serial-number {
  margin-right: auto;
  width: fit-content;

}

.topbar .serial-number-row {
  width: 500px;
}
.topbar .phone-number-row {
  width: 440px;
  
}
.topbar .profile-box {
  display: flex;
  align-items: center;

}
.topbar .navbar-nav {
  gap: 40px;
  justify-content: end;
  align-items: center;

}
.topbar .error-input-field {
  border-color: #f46f6f !important;
  border-width: 1px solid !important;
}
.topbar .input-component-main .rounded-input-box {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.topbar .input-container {
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
}


.topbar .input-component-main .input-image {
  position: absolute;
  right: 10px;
  margin: 0px !important;
}
.topbar .search-button{
  cursor: pointer;
}
.topbar .test-nanv{
  background-color: #294566;
}
@media (max-width: 576px) {
  .topbar .topbar-container {
    padding: 0px 15px;
  }
}
