.pagination-component {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.pagination-component .left-arrow-botton {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #bebebe;
  background: #fff;
  text-align: center;
}

.pagination-component .right-arrow-botton {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #bebebe;
  background: #fff;
  text-align: center;
  margin-left: 15px;
}

.pagination-component button:disabled {
  opacity: 0.4;
}
