.user-details .page-title {
  color: #323c4d;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.user-details .input-label {
  color: #2b2b2b;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.user-details .button-align {
  margin-top: 30px;
  float: right;
}
