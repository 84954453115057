.modal-component .button-center {
  justify-content: center !important;
}

.modal-component .modal-footer {
  border-top: 0px !important;
}

.modal-component .modal-header {
  border-bottom: 0px !important;
}

.modal-component .modal-title {
  color: #f39200;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.modal-component .modal-header {
  padding: 0px;
}

.modal-component .modal-body {
  padding: 0px;
}

.modal-component .modal-footer {
  padding: 0px;
}

.modal-component .modal-content {
  padding: 30px;
}
.modal-footer > * {
  margin: 0px !important;
}

.modal-component .left-margin {
  margin-left: 20px;
}


@media (max-width: 390px) {
  .modal-component .left-margin {
    margin-left: 10px;
  }
}
