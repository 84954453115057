.water-heater-data-main .settings-button {
  background-color: #ffffff !important;
  border: 1px solid #f0f2f4 !important;
  border-radius: 5px;
  padding: 10px;
  width: 50px;
}
.water-heater-data-main .modal-title {
  color: #f39200;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  line-height: 25px;
}
.water-heater-data-main .checkbox-items {
  display: flex;
  padding: 10px;
}
.water-heater-data-main .modal-content {
  padding: 20px;
}
.water-heater-data-main .parameter-modal {
  width: 75%;
}
.water-heater-data-main .device-data-tilte {
  font-size: 20px;
  font-weight: 700;
  color: #323c4d;
}
.water-heater-data-main .device-heading-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: -10px;
}
.water-heater-data-main .icon-button-row {
  display: flex;
  gap: 20px;

}
.water-heater-data-main .checkbox-modal-footer {
  display: flex;
  gap: 20px;
  border-top: 1px solid #efefef;
  width: 100%;
  justify-content: end;
  padding: 30px 0px 10px 0px !important;
}

.water-heater-data-main .settings-button img {
  margin-left: 0px !important;
}

/* Table styles */
.water-heater-data-main .table {
  text-align: center;
}

.water-heater-data-main .table .sub-heading th {
  color: #323c4d !important;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  border-bottom: 0px;
  padding: 20px !important;
  border-right: 1px solid #ececec;
}
.water-heater-data-main .table .sub-heading th:first-child {
  position: sticky;
  left: 0;
  background: white;
}

.water-heater-data-main .table .sub-heading th:last-child {
  border-right: 0px !important;
}

.water-heater-data-main .table .main-heading {
  background: #f5f5f5;
  text-align: left;
  position: sticky;
  top: 0;
}

.water-heater-data-main .table .main-heading th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 1.5px solid #ececec;
  min-width: 150px;
  background: #f5f5f5 !important;
  position: sticky;
  left: 0;
}

.water-heater-data-main .table .main-heading th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.water-heater-data-main .table .main-heading th:last-child span {
  position: sticky;
  left: 160px;
  top: 0;
}

.water-heater-data-main .table .main-heading th {
  color: #323c4d;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  border-bottom: 0px;
  padding: 20px !important;
}

.water-heater-data-main .table tbody tr td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  text-align: left;
  min-width: 150px;
  background: #fafbfc;
  position: sticky;
  left: 0;
}

.water-heater-data-main .table tbody tr td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right: 0px;
}

.water-heater-data-main .table tbody tr td {
  font-size: 14px;
  color: #606c80;
  font-weight: 400;
  border-bottom: transparent;
  border-right: 1px solid #ececec;
  background: #fafbfc;
}

.water-heater-data-main .table tbody tr td {
  padding: 12px !important;
  vertical-align: middle;
}

.water-heater-data-main .table tbody hr {
  margin: 5px !important;
  border-width: 0px !important;
}
/* modal styles */
.water-heater-data-main .modal-header {
  padding: 10px 10px 0px 10px !important;
  border-bottom: 0px !important;
}

.water-heater-data-main .modal-header .title-container {
  border-bottom: 1px solid #efefef;
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
}

.water-heater-data-main .parameter-modal .modal-body {
  padding: 22px 0px !important;
}

.water-heater-data-main .modal-footer {
  border-top: 0px !important;
  padding: 15px 10px;
}

.water-heater-data-main .pagination-mt {
  margin-top: 30px;
}

.water-heater-data-main .data-time {
  color: #848d9b;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}

.water-heater-data-main table {
  border-collapse: separate;
  border-spacing: 0px;
}

.water-heater-data-main .data-date {
  line-height: 14px;
}

.water-heater-data-main .no-data-container {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.water-heater-data-main .no-data-title {
  color: #323c4d;
  font-size: 18px;
  line-height: normal;
  font-weight: 500;
  margin-top: 20px;
}

.water-heater-data-main .no-data-text {
  color: #606c80;
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  margin-top: 5px;
}
.water-heater-data-main .refresh-button-label {
  color: #f39200;
  text-align: left;
  display: flex;
}
.water-heater-data-main p {
  margin-top: 10px;
  margin-bottom: 0px !important;
}
.arrow_box {
  background: white;
  border-radius: 8px;
  position: relative;
  color: #f39200;
  max-width: 480px;
  min-height: 35px;
  margin-right: 15px;
  padding-right: 26px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  padding-left: 17px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.arrow_box:after {
  content: "";
  position: absolute;
  right: -5px;
  top: 35%;
  background: white;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.water-heater-data-main .select-all-checkbox {
  border-color: #f39200;
  margin-top: 5px;
}
.water-heater-data-main .export-graph-button-disable{
  background-color:#f4c179;
  border: none;
}

.water-heater-data-main .settings-button-disable{
  background-color:white;
  border: none; 

}
.water-heater-data-main .water-heater-selcted-date-range{
  color: #606C80;
  font-size: 12px;
  line-height: normal;
  font-weight: 600;
}
.water-heater-data-main .selcteddate-range{
  color:#606C80;
  font-size: 12px;
  line-height: normal;
  font-weight: 400;
  margin-top: 5px;
}
