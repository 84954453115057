.button-component .colored-button {
  background-color: #f39200;
  border: 1px solid #f39200;
  border-radius: 5px;
  color: white;
  height: 45px;
  text-align: center;
  padding: 10px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.button-component .outlined-button {
  background-color: transparent;
  border: 1px solid #f39200;
  border-radius: 5px;
  color: #f39200;
  height: 45px;
  text-align: center;
  padding: 10px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.button-component button img {
  margin-left: 10px;
}

.button-component .outlined-button:hover {
  background-color: rgba(243, 146, 0, 0.2);
  transition: 0.1s;
}
