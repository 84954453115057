.time-inteval-modal .time-interval-container {
  border: 1px solid #f0f2f4;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  white-space: nowrap;
}

.time-inteval-modal .time-interval-container img {
  width: 50%;
}

.time-inteval-modal .time-interval-container .time-label {
  color: #606c80;
  font-size: 14px;
}

.time-inteval-modal .modal-width {
  width: 380px;
}

.time-inteval-modal .modal-footer {
  border-top: 1px solid #efefef !important;
  padding-top: 30px !important;
  margin-top: 30px !important;
}

.time-inteval-modal .submit-button:disabled {
  opacity: 0.5;
}

@media (max-width: 576px) {
  .time-inteval-modal .modal-width {
    width: auto;
  }
}
