.retrofit-dashboard-page .inputBox {
  display: flex;
}
.retrofit-dashboard-page .title-none {
  display: none;
}
.retrofit-dashboard-page .error-input-field {
  border-color: #f46f6f !important;
  border-width: 1px solid !important;
}
.retrofit-dashboard-page .valid-input-field {
    border-color: #f0f2f4 !important;
  }
.retrofit-dashboard-page .submit-button {
    width: 100%;
    border-radius: 60px !important;
}
.retrofit-dashboard-page .customRounded-dropdown.highlighted .ripple.ripple-surface {
  border-color: #f46f6f !important;
  border-width: 1px !important;
  border-style: solid !important;
}