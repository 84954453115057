.dashboard-page .inputBox {
  display: flex;
}
.dashboard-page .theme-card-component .card-compponent {
  width: 100%;
  padding: 30px;
  background: white;
  justify-content: center;
  align-items: center;
}

.dashboard-page .title-none {
  display: none;
}
.dashboard-page .card-box-style {
  display: flex;
  width: 68%;
  margin-bottom: 30px;
  background-color: cornflowerblue;
}
.modal-item-display {
  margin-top: 30px;
  color: #323c4d;
  font-size: 20px;
  font-weight: 700;
}

.dashboard-page .card-row {
  margin-bottom: 30px;
  margin-top: 30px;
}
.dashboard-page .card-online {
  padding: 0px;
  margin-right: 12px;
}
.dashboard-page .card-width {
  width: 248px !important;
}

.dashboard-page .error-input-field {
  border-color: #f46f6f !important;
  border-width: 1px solid !important;
}
.dashboard-page .valid-input-field {
  border-color: #f0f2f4 !important;
}
.dashboard-page .count-loader {
  color: white;
}
.dashboard-page .error-input-field .dropDown-toggle {
  border: 1px solid #f46f6f !important;
}
.dashboard-page .device-card-title {
  margin-bottom: 30px;
}
.dashboard-page .device-card-main .card-compponent {
  padding: 30px 30px 40px 30px;
}
.dashboard-page .dashboard-submit-button{
  border-radius: 60px;
}
/* nwchanges */
.dashboard-page .information-icon {
  /* float: right; */
  cursor: pointer;
  padding: 0px 0px 8px 10px;

}
.dashboard-page .badge-modal .command-badge-modal-width {
  width: 380px;
}
.dashboard-page .calculation-description{
  padding-top: 15px;
  font-size: 15px;
}
.dashboard-page .badge-modal .colored-button {
  display: none;
}
.dashboard-page .badge-modal .outlined-button {
  width: 320px;
}
.dashboard-page .device-card-title{
  display: none
}
.dashboard-page .textadd-devices {
  color: #f39200;
  font-weight: 600;
  font-size: 16px;
 
}
.dashboard-page .addDevices-status {
  display: flex;

}
