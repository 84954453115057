.plug-notify-configuration .notify-heading {
    color: #323c4d;
    font-size: 20px;
    font-weight: 700;
  }
  .plug-notify-configuration .input-Box{
    display: flex;
 
  }
  .plug-notify-configuration .notifybox {
    margin-bottom: 10px;
  }

  .plug-notify-configuration .notification-icon {
    cursor: pointer;
    padding: 0px 0px 8px 10px;
  }
  .plug-notify-configuration .notify-alert-box{


    display: flex;
    margin-top: -20px;
  }
  .plug-notify-configuration .notify-label{
    color: #2B2B2B;
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
    margin-top: 5px;
  }
  .plug-notify-configuration .notify-main-div{
    margin-top: 30px;
  }
  .plug-notify-configuration .notifyIcon-style{
    width: 13px;
  }
  .plug-notify-configuration .cancel-button{
   background-color: white;
   color: #f39200;
  }
  .plug-notify-configuration .button-container{
    display: flex;
    flex-direction: row-reverse;
    gap: 22px;
    margin-top: 20px;
    
  }
  .plug-notify-configuration .badge-modal .command-badge-modal-width {
    width: 380px;
  }
  .plug-notify-configuration .calculation-description{
    padding-top: 15px;
    font-size: 15px;
  }
  .plug-notify-configuration .badge-modal .colored-button {
    display: none;
  }
  .plug-notify-configuration .badge-modal .outlined-button {
    width: 320px;
  }


  .plug-notify-configuration .input-box .inputbox-alert-notify{
    background-color: red;
    width :10px;
  }
  .plug-notify-configuration .error-input-field {
    border-color: #f46f6f !important;
    border-width: 1px solid !important;
  }
  .plug-notify-configuration .notify-submit-button{
    border-radius: 5px;
  }