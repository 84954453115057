.rmdp-input {
  border: transparent;
}
.rmdp-input:focus {
  border: transparent;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #f39200 !important;
  box-shadow: 0 0 3px #f39200 !important;
  color: #fff !important;
}

.rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background-color: white !important;
  color: black !important;
  border: 1px solid #f39200 !important;
  border-radius: 20px !important;
}
.rmdp-day.rmdp-today span {
  background-color: #f39200 !important;
}

.rmdp-arrow {
  border: solid black !important;
  border-width: 0px 1px 1px 0px !important;
}
.rmdp-arrow-container {
  background-color: #eff2f7 !important;
  border-radius: 5px !important;
  width: 18px !important;
  height: 18px !important;
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid black !important;
  border-width: 0px 1px 1px 0px !important;
}
.rmdp-arrow-container:hover {
  background-color: #eff2f7 !important;
  border-radius: 5px !important;
  width: 18px !important;
  height: 18px !important;
}
