.card-component {
  width: 100%;
  height: 116px;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.card-component .inner-container {
  position: absolute;
  justify-content: space-between;
  width: 100%;
  padding: 0px 21px;
  align-items: center;
}
.card-component .count {
  margin-bottom: 0px;
  color: white;
  font-size: 35px;
  font-weight: 800;
  line-height: 40px;
} 
.card-component .text {
  margin-bottom: 0px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  
}
.card-component .content-container {
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  box-sizing: content-box;
}
