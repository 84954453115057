.login-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page .forgot-password-txt {
  font-weight: 500;
  font-size: 14px;
  color: #f39200;
  cursor: pointer;
  float: right;
}

.login-page .icon-input {
  position: relative;
}

.login-page .eye-icon {
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
}


.login-page .theme-btn .button-item {
  background: #f39200 !important;
  border: 1px solid #f39200 !important;
}
.auth-box {
  background: #ffffff;
  border-radius: 20px;
  padding: 48px 45px 40px 45px;
}
.logo-div {
  display: flex;
  justify-content: center;
}
.form-title {
  font-weight: 700;
  font-size: 26px;
  color: #323c4d;
  margin-top: 40px;
}
.input-label {
  color: #141736;
  font-weight: 500;
  font-size: 14px;
}
.button-login{
  margin-top: 60px;
}
.passwordInput {
  position: relative;
}
.eye-Icon-Vsisble{
  position: relative;
}
i.bi.bi-eye-slash {
  position: absolute;
  right: 0;
  bottom: 10px;
  padding: 0px 13px !important;
}
 .error-msg {
  color:#f46f6f !important
 
}