.user-managment-main-page .user-managment-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  margin-top: -10px;
}
.user-managment-main-page .user-managment-heading {
  color: #323c4d;
  font-size: 20px;
  font-weight: 700;
}
.user-managment-main-page .action-column {
  display: flex;
  gap: 20px;
  cursor: pointer;
}

.user-managment-main-page .action-column img {
  cursor: pointer;
}

.user-managment-main-page .table-container tr.tbody-tr td {
  padding: 15px 26px !important;
}
.user-managment-main-page .pagination-button {
  margin-top: 30px;
}
