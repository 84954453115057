.form-check-input[type="checkbox"]:checked {
  background-image: none;
  background-color: #f39200 !important;
  width: 20px !important;
  height: 20px !important;
}
.form-check-input:checked {
  border-color: #f39200 !important;
}
.form-check-input {
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #d7d7d7;
}
.form-check-label {
  font-style: normal;
  color: #606c80;
  font-weight: 500;
  font-size: 12px;
}
.form-check-input:before {
  box-shadow: 0px 0px 0px 13px transparent !important;
}
.form-check-input[type="checkbox"]:checked:after {
  /* margin-top: 0px !important; */

      margin-left: 5px !important;
    margin-top: 1px !important;
    width: 5px !important;
    height: 11px !important
}
