.custom-legend {
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.legend-item-false {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-weight: bold;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 10px;
  color: black;
  font-weight: 400;
  font-size: 12px;
  background-color: transparent;
}
.legend-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-weight: bold;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 10px;
  color: black;
  font-weight: 400;
  font-size: 12px;
  background-color: transparent;
}

.legend-color {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  border-radius: 50%;
}
.legend-item:active {
  background-color: #eeeeee;
}
.legend-item:focus {
  background-color: transparent;
}
.legend-item-true {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-weight: bold;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 10px;
  color: black;
  font-weight: 400;
  font-size: 12px;
  background-color: #eeeeee;
}

.legend-container {
  overflow-y: hidden;
}

.legend-container > canvas {
  left: 0;
  top: 0;
  pointer-events: none;
}

.chartAreaWrapper {
  width: 1500px;
}

/* width */
.legend-container::-webkit-scrollbar {
  height: 10px;
}

/* Track */
.legend-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 15px;
}

/* Handle */
.legend-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

/* Handle on hover */
.legend-container::-webkit-scrollbar-thumb:hover {
  background: #888383;
}
