.customRounded-dropdown .dropDown-toggle {
  background-color: #f5f5f5 !important; /* Set to transparent */
  color: #606c80 !important; /* Text color */
  border-radius: 60px !important; /* Border radius */
  font-size: 14px; /* Font size */
  font-weight: 400; /* Font weight */
  width: 100%;
  height: 45px;
  justify-content: space-between;
  text-overflow: ellipsis;
  display: flex !important;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  text-transform:none;
  position: relative;
}
.customRounded-dropdown .selected-text {
  background-color: #f5f5f5 !important; /* Set to transparent */
  color: #606c80 !important; /* Text color */
  border-radius: 60px !important; /* Border radius */
  font-size: 14px; /* Font size */
  font-weight: 600; /* Font weight */
  text-transform:none
}

/* If you want to remove the default down arrow */
.customRounded-dropdown .dropdown-toggle::after {
  content: url("../../assets/images/downArrow.png") !important;
  border: none !important;
  position: absolute;
  right: 20px;
  top: 11px;
}
.customRounded-dropdown .menuItem {
  width: 100%;
  margin-right: 5px;
}

.customRounded-dropdown .dropdown .btn-primary {
  box-shadow: none !important;
}

.customRounded-dropdown .dropdown .btn-primary:first-child:hover {
  box-shadow: none !important;
}

.customRounded-dropdown .dropdown .btn-primary:hover,
.btn-primary:focus {
  box-shadow: none !important;
}

.customRounded-dropdown .dropdown .btn-primary:focus {
  box-shadow: none !important;
}

.customRounded-dropdown ul li:hover {
  cursor: pointer;
  background: rgba(243, 146, 0, 0.12);
}
.customRounded-dropdown li {
  height: 35px;
  padding-left: 25px;
  padding-top: 8px;
}
