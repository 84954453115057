.forgot-password-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot-password-page .forgot-text {
  font-weight: 400;
  font-size: 14px;
  color: #606c80;
  line-height: 19px;
}

.back-login {
  font-weight: 600;
  font-size: 14px;
  color: #f39200;
  cursor: pointer;
}

.error-msg {
  color: red;
  font-size: 12px;
  font-weight: normal;
}

.input-item {
  color: #141736 !important;
}
