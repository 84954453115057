.daterange-picker {
  position: relative;
}

.date-range-input {
  height: 45px;
  border: 1px solid #f0f2f4;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  color: #606c80;
  font-size: 14px;
  padding: 0px 20px;
  caret-color: transparent;
}

.daterange-picker .input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.daterange-picker .input-container img {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.daterange-picker .close-button {
  width: 15px;
}

/* New------------------------------------------------------- */

.daterange-picker .rdrDateRangeWrapper {
  position: absolute;
}
.daterange-picker .rdrDateDisplayWrapper {
  display: none;
}

.daterange-picker .rdrMonthsVertical {
  flex-direction: unset;
}

.daterange-picker .rdrCalendarWrapper {
  width: 100%;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  margin-top: 10px;
  padding-bottom: 15px;
}

.rdrMonthAndYearPickers select:hover {
  background: none !important;
  background: url("../../assets/images/icons/Down-arrow.svg") no-repeat !important;
  background-position: right 5px center !important;
}

.daterange-picker .rdrMonthAndYearPickers select {
  color: #606c80;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px 10px 0px;
  background: url("../../assets/images/icons/Down-arrow.svg") no-repeat;
  background-position: right 5px center;
}

.daterange-picker .rdrNextPrevButton {
  margin: 0px;
  width: 18px;
  height: 18px;
  position: relative;
}

.daterange-picker .rdrMonthAndYearWrapper {
  padding: 10px 15px;
}

.daterange-picker .rdrPprevButton i {
  display: none;
}

.daterange-picker .rdrPprevButton::before {
  content: url("../../assets/images/icons/Calendar-left-arrow.svg");
  position: absolute;
  top: -1px;
  left: 6px;
}

.daterange-picker .rdrNextButton i {
  display: none;
}

.daterange-picker .rdrNextButton::before {
  content: url("../../assets/images/icons/Calendar-right-arrow.svg");
  position: absolute;
  top: -1px;
  right: 6px;
}

.daterange-picker .rdrDayDisabled {
  background-color: transparent !important;
  color: red !important;
}

.rdrDayDisabled .rdrInRange,
.rdrDayDisabled .rdrStartEdge,
.rdrDayDisabled .rdrEndEdge,
.rdrDayDisabled .rdrSelected,
.rdrDayDisabled .rdrDayStartPreview,
.rdrDayDisabled .rdrDayInPreview,
.rdrDayDisabled .rdrDayEndPreview {
  background: transparent !important;
  filter: none !important;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: transparent !important;
}

.rdrDayPassive .rdrDayNumber span {
  color: transparent !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #f39200 !important;
}

.rdrDayDisabled .rdrDayNumber span {
  color: #aeb9bf !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: transparent !important;
}
