#sidebar .navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  position: absolute;
  right: -13px;
  background: #ffffff;
  color: #f39200;
  cursor: pointer;
  box-shadow: 0px 0px 15px 0px #d1d1d1;
  align-items: center;
  justify-content: center;
}

#sidebar.active .drop-arrow-icon {
  position: absolute;
  right: 5px;
  top: 15px;
}

i,
span {
  display: inline-block;
}

/* ---------------------------------------------------
        SIDEBAR STYLE
    ----------------------------------------------------- */

.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  min-width: 245px;
  max-width: 245px;
  background: #ffffff;
  color: #606c80;
  transition: all 0.3s;
  padding: 10px;
  position: relative;
  border-right: 1px solid rgba(223, 223, 223, 0.5);
  height: calc(100vh - 80px);
  position: fixed;
  z-index: 99;
  margin-top: 80px;
}

#sidebar ul {
  margin-top: 25px;
  padding-left: 0px !important;
}

#sidebar ul li {
  padding: 13px 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: block;
  margin-top: 5px;
  cursor: pointer;
  position: relative;
  transition: 0.1s ease-in-out;
}

#sidebar ul li:hover {
  color: #f39200;
  font-weight: 500;
  background: rgba(243, 146, 0, 0.12);
  border-radius: 5px;
  transition: 0.1s ease-in-out;
}

.active-item {
  color: #f39200;
  background: rgba(243, 146, 0, 0.12);
  border-radius: 5px;
  transition: 0.1s ease-in-out;
}

#sidebar ul li span {
  margin-left: 35px;
  position: absolute;
  line-height: 20px;
}

#sidebar.active {
  min-width: 70px;
  max-width: 70px;
}

#sidebar.active li span {
  display: none;
}

#sidebar.active li {
  padding: 10px 7px;
  text-align: center;
  justify-content: center;
}

#sidebar.active .navbar-btn img {
  transform: rotateZ(180deg);
  transition: 0.1s;
}

.width-lg {
  max-width: 245px;
  min-width: 245px;
  transition: 0.2s ease-in-out;
}

.width-sm {
  max-width: 80px;
  min-width: 80px;
  transition: 0.2s ease-in-out;
}

#sidebar.active #content {
  width: calc(100% -80px);
}

#sidebar ul li img {
  filter: hue-rotate(196deg) brightness(65%) sepia(0.3);
}

#sidebar ul li:hover img {
  filter: none;
  transition: 0.1s ease-in-out;
}

#sidebar ul li {
  display: flex;
  align-items: center;
}

/* ---------------------------------------------------
      MEDIAQUERIES
  ----------------------------------------------------- */

@media (max-width: 991px) {
  #sidebar {
    margin-top: 70px;
  }
}

@media (max-width: 768px) {
  #sidebar {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
  }

  .width-lg {
    min-width: 80px;
    max-width: 80px;
    transition: 0.2s ease-in-out;
  }

  #sidebar .navbar-btn {
    display: none;
  }

  #sidebar ul {
    margin-top: 0px;
    padding-left: 0px !important;
  }

  #sidebar ul li {
    padding: 10px 10px;
  }

  #sidebar ul li span {
    display: none;
  }

  #sidebar ul ul a {
    padding: 10px !important;
  }
}
.active-sidebar {
  color: #f39200;
  font-weight: 500;
  background: rgba(243, 146, 0, 0.12);
  border-radius: 5px;
  transition: 0.1s ease-in-out;
}
.active-sidebar img {
  filter: none !important;
}

.version-box {
  position: absolute;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 30px 10px 30px;
  border-radius: 5px;
  gap: 2px;
  border: 2px dashed #dedede;
  color: #606c80;
  margin-left: 20px;
  font-size: 12px;
  background-color: #FCFCFC;
}
.version-box .version-number {
  color: #f39200;
  font-weight: 700;
  font-size: 12px;
}
.hide-version {
  display: none;
}
