.home-page-main .home-page-background {
  background-image: url("../../../assets/images/BG-Pattern.svg");
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}
.home-page-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-page-title {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #323C4D;
}
@media screen and (max-width: 576px) {
  .home-page-main .home-page-background {
    height: 100vh;
    margin-top: 200px;
  }
  .home-page-title {
  
  margin-top: 20px;
}
}
