.disabled-box {
  /* padding-left: 20px !important; */
  background-color: #f5f5f5;
  border-radius: 40px !important;
  width: 100%;
  height: 45px;
  border: 1px solid #f0f2f4;
  border-radius: 5px;
  color: #606c80;
  padding: 10px 20px 10px 20px;
  border: 1px solid #f0f2f4;
}
.disabled-box .disabled-text {
  font-weight: 300;
  font-size: 14px;
  font-style: normal;
}
.disabled-box .disabled-value {
  margin-left: 2px;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
}
