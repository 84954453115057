.device-view-logs .device-log-heading {
  font-size: 20px;
  color: #323c4d;
  font-weight: 700;
  margin-left: 0px;
  margin-bottom: 30px;
}
.device-view-logs .time-stamp-row {
  padding: 10px;
  border-radius: 5px;
}

.device-view-logs .error-text-box {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}

.device-view-logs .error-tooltip {
  position: relative;
}
.device-view-logs .error-text-on-hover {
  width: max-content;
  display: none;
  position: absolute;
  background-color: #fff2f2;
  padding: 15px 20px;
  border: 1px solid #ffdcdc;
  border-radius: 5px;
  color: #ff6174;
  right: -20px;
  z-index: 9;
  line-height: 20px;
  top: 30px;
  box-shadow: -3px 9px 14px -2px rgb(0 0 0 / 5%);
}

.error-icon:hover .error-text-on-hover {
  display: block;
}
.device-view-logs .device-log-card-title {
  display: none;
}

.error-text-on-hover:after,
.error-text-on-hover:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  left: 90;
}

/* this border color controlls the color of the triangle (what looks like the fill of the triangle) */
.error-text-on-hover:after {
  right: 20px;
  top: -15px;
  border-color: transparent transparent #fff2f2 transparent;
  border-width: 8px;
}

/* this border color controlls the outside, thin border */
.error-text-on-hover:before {
  top: -15px;
  border-color: transparent transparent #ff6174 transparent;
  border-width: 8px;
  right: 20px;
}

.device-view-logs .command-logs-table tbody tr td:last-child {
  width: 150px;
  position: relative;
}

.device-view-logs .pagination-mt {
  margin-top: 30px;
}
