.date-picker-modal {
  border-top: 1px solid #efefef;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #efefef;
}
.date-modal-main .rdrDateDisplayWrapper {
  display: none;
}
.date-modal-main .input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 340px;
  margin-bottom: 20px;
}
.date-modal-main .date-show-box {
  position: relative;
  width: 250px;
}
.date-modal-main .calendar-icon {
  position: absolute;
  right: 14px;
  top: 14px;
}
.date-range-modal-width {
  width: 400px !important;
}
.date-modal-main .modal-component .modal-title {
  padding-bottom: 20px !important;
}
.date-modal-main .rdrCalendarWrapper {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
}
.date-modal-main .date-range-title {
  color: #606c80;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}
.date-modal-main .rdrMonthAndYearWrapper {
  padding-top: 0;
  padding-left: 17px;
  padding-right: 17px;
  border-bottom: 1px solid #f2f2f2;
}
.date-modal-main .rdrMonth {
  padding-right: 17px;
  padding-left: 17px;
  padding-bottom: 20px;
}
.rdrDayDisabled {
  background-color: red;
}
.date-modal-main .rdrPprevButton i {
  display: none;
}

.date-modal-main .rdrPprevButton::before {
  content: url("../../assets/images/icons/Calendar-left-arrow.svg");
  position: absolute;
  top: 107px;
  left: 36px;
}

.date-modal-main .rdrNextButton i {
  display: none;
}

.date-modal-main .rdrNextButton::before {
  content: url("../../assets/images/icons/Calendar-right-arrow.svg");
  position: absolute;
  top: 107px;
  right: 36px;
}

.date-modal-main .rdrDay {
  background-color: transparent !important;
}
.date-modal-main .rdrMonthsVertical {
  align-items: center !important;
}

.date-modal-main .rdrMonthAndYearPickers select {
  background: none !important;
  background: url("../../assets/images/icons/Down-arrow.svg") no-repeat !important;
  background-position: right 5px center !important;
}

.date-modal-main .date-input {
  height: 45px;
  border: 1px solid #f0f2f4;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  color: #606c80;
  font-size: 14px;
  padding: 0px 20px;
  caret-color: transparent;
}
.submit-disable {
  opacity: 50% !important;
}
/* -------------time picker---------------- */
.date-modal-main .time-range-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
}
.date-modal-main .time-range-selection .text-box {
  color: #9ba5b5;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.date-modal-main .time-picker {
  width: 133px;
  height: 45px;
  flex-shrink: 0;
}
.date-modal-main .react-time-picker__wrapper {
  border-radius: 5px;
  border: 1px solid #f0f2f4 !important;
  color: #606c80;
}
.date-modal-main .react-time-picker__inputGroup__input:focus-visible {
  outline: 0px !important;
}
.date-modal-main
  .react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour {
  margin-left: 15px !important;
}
.date-modal-main .react-time-picker__inputGroup__input:invalid {
  background: none !important;
}
.date-modal-main .react-time-picker__inputGroup {
color: #606C80;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
