.plug-trends-main .trends-export-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  margin-top: -10px;
}
.plug-trends-main .trends-button {
  width: 120px !important;
}

.plug-trends-main .trends-heading {
  color: #323c4d;
  font-size: 20px;
  font-weight: 700;
}
.plug-trends-main .trend-date-range-picker {
  float: right;
  position: relative;
}
.plug-trends-main .daterange-picker {
  width: 255px;
}
.plug-trends-main .legend-radio-button {
  display: flex;
  border: 1px solid #f39200;
  padding: 6px;
  border-radius: 5px;
  justify-content: center;
  gap: 7px;
  font-size: 13px;
  width: 100px;
  background-color: #f392001a;
  cursor: pointer;
}
.plug-trends-main .legend-section {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.plug-trends-main .radio-button .form-check-input[type="radio"] {
  margin-top: 2px !important;
  margin-right: 0px;
}
.plug-trends-main .device-selection-card {
  margin-bottom: 20px;
}
.plug-trends-main .clicked-legend-radio-button {
  display: flex;
  border: 1px solid #eaeaea;
  padding: 6px;
  border-radius: 5px;
  justify-content: center;
  gap: 7px;
  font-size: 13px;
  width: 100px;
  cursor: pointer;
}
/* default styling for radio button */

.plug-trends-main .clicked-legend-round-button {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-color: inherit;
  color: #017b5f;
  border: 2px solid #eaeaea;
  border-radius: 24px;
}

.plug-trends-main .clicked-legend-round-button::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  background-color: #f39200;
  border-radius: 12px;
}

.plug-trends-main .clicked-legend-round-button {
  border: 2px solid #f39200;
  transform: scale(0.75);
}
.plug-trends-main .legend-round-button {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: middle;
  background-color: inherit;
  color: #f39200;
  border: 2px solid #eaeaea;
  border-radius: 24px;
}
.plug-trends-main .dropdown-margin-top {
  margin-top: 35px;
}

.plug-trends-main .select-tab .nav-tabs {
  border-bottom: 1px solid #efefef;
  margin-bottom: 30px;
}

.plug-trends-main .select-tab .nav-link {
  padding: 17px 0px 10px 0px !important;
  margin-right: 40px;
  font-weight: 500;
  font-size: 13px;
  color: #606c80;
  text-transform: none !important;
}

.plug-trends-main .select-tab .nav-tabs .nav-link:hover {
  background: transparent;
  color: #2b2b2b;
}

.plug-trends-main .select-tab .nav-tabs .nav-link.active {
  border-bottom: 1px solid #f5a833;
  color: #2b2b2b;
}
.plug-trends-main .line-color {
  border: 1px solid #efefef;
}
.plug-trends-main .data-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}

.plug-trends-main .selected-value-box {
  height: 45px;
  padding: 15px;
  border: 1px solid #f0f2f4;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  color: #606c80;
  font-size: 14px;
  font-weight: 400;
}

.plug-trends-main .selected-value-box img {
  cursor: pointer;
  margin-left: 25px;
}
.plug-trends-main .error-text {
  color: #f46f6f;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0px;
  display: block;
}

.device-control-page .display-none {
  display: none;
}

.plug-trends-main .color-dot {
  height: 14px;
  width: 14px;
  margin-right: 10px;
  border-radius: 50%;
}

.plug-trends-main .add-button {
  display: flex;
  align-items: center;
  height: 45px;
  cursor: pointer;
}

.plug-trends-main .add-button img {
  margin-right: 10px;
}

.plug-trends-main .add-button .button-text {
  color: #f39200;
  font-size: 14px;
  font-weight: 500;
}
.plug-trends-main .notice-text {
  color: #606c80;
  font-size: 12px;
  font-weight: 400;
}
/* export graph modal */
.plug-trends-main .export-graph-container {
  border: 1px solid #f0f2f4;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  white-space: nowrap;
}

.plug-trends-main .export-graph-container img {
  width: 50%;
}

.plug-trends-main .export-graph-container .time-label {
  color: #606c80;
  font-size: 14px;
}
.plug-trends-main .add-modal-width {
  width: 340px !important;
}
.plug-trends-main .outlined-button {
  width: 100%;
}
.plug-trends-main .colored-button {
  width: 100%;
}

.plug-trends-main .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.plug-trends-main .modal-button-width {
  width: 130px !important;
}
.plug-trends-main .export-graph-validation {
  border: 1px solid red;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  white-space: nowrap;
}
.plug-trends-main .export-graph-validation .time-label {
  color: #606c80;
  font-size: 14px;
}
.plug-trends-main .border-red {
  border: 1px solid #f46f6f !important;
}
.plug-trends-main .card-title {
  display: none;
}
.plug-trends-main .card-compponent {
  padding: 20px 30px 30px 30px;
}

.plug-trends-main .content-margin {
  margin-top: 10px;
  margin-bottom: 14px;
}

.location-label {
  font-size: 12px;
  font-weight: 400;
  color: #2b2b2b;
}

.plug-trends-main .dropdown-margin-top {
  margin-top: -10px;
}
.plug-trends-main .trends-graph {
  margin-top: 30px;
}
.plug-trends-main .yaxis-label {
  position: absolute;
  margin-top: -200px;
  transform: rotate(-90deg);
  left: -55px;
  color: #4f4f4f;
  font-weight: 400;
  font-size: 11px;
}
.plug-trends-main .plug-trends-chart {
  position: relative;
}
.plug-trends-main .trend-date-range-picker {
  width: 265px;
}
.plug-trends-main .data-unavailable-placeholder {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 100px;
}
.plug-trends-main .data-unavailable-placeholder div {
  font-weight: 700;
  font-size: 16px;
  color: #323c4d;
}
.plug-trends-main .graph-top-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.plug-trends-main .information-icon {
  float: right;
  cursor: pointer;
}
.plug-trends-main .badge-modal .command-badge-modal-width {
  width: 380px;
}
.plug-trends-main .calculation-description{
  padding-top: 15px;
  font-size: 15px;
}
.plug-trends-main .badge-modal .colored-button {
  display: none;
}
.plug-trends-main .badge-modal .outlined-button {
  width: 320px;
}