.custom-dropdown .dropDown-toggle {
  background-color: transparent !important; /* Set to transparent */
  color: #606c80 !important; /* Text color */
  padding: 10px 20px; /* Padding */
  border: 1px solid #606c80 !important; /* Border */
  border-radius: 5px; /* Border radius */
  font-size: 14px; /* Font size */
  font-weight: 400; /* Font weight */
  width: 100%;
  justify-content: space-between;
  text-overflow: ellipsis;
  display: flex !important;
  overflow: hidden;
  white-space: nowrap;
}

/* If you want to remove the default down arrow */
.custom-dropdown .dropdown-toggle::after {
  content: url("../../assets/images/downArrow.png") !important;
  border: none !important;
  padding-left: 5px;
}
.custom-dropdown .menuItem {
  width: 100%;
  margin-right: 5px;
  max-height: 300px;
  overflow-y: overlay;
}
.custom-dropdown .dropdown .btn-primary {
  box-shadow: none !important;
}

.custom-dropdown .dropdown .btn-primary:first-child:hover {
  box-shadow: none !important;
}

.custom-dropdown .dropdown .btn-primary:hover,
.btn-primary:focus {
  box-shadow: none !important;
}

.custom-dropdown .dropdown .btn-primary:focus {
  box-shadow: none !important;
}

.custom-dropdown ul li:hover {
  background: rgba(243, 146, 0, 0.12);
}
.custom-dropdown li {
  height: 35px;
  padding-left: 25px;
  padding-top: 8px;
}
.custom-dropdown .ripple.ripple-surface {
  color: #606c80 !important;
  font-size: 14px;
  font-weight: 400;
  border-color: #efefef !important;
}

.custom-dropdown .dropDown-toggle {
  text-transform: none !important;
}

/* width */
.custom-dropdown ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.custom-dropdown ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
.custom-dropdown ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.custom-dropdown ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
