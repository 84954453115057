/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

.layout-page #content {
  padding: 30px 40px 30px 40px;
  min-height: calc(100vh - 80px);
  transition: all 0.3s;
  margin-top: 80px;
}

.content-lg {
  width: calc(100% - 245px);
  transition: all 0.3s;
}

.content-sm {
  width: calc(100% - 80px);
  transition: all 0.3s;
}

@media (max-width: 768px) {
  .layout-page #content {
    padding: 35px 40px;
    min-height: calc(100vh - 80px);
    transition: all 0.3s;
    width: calc(100% - 80px);
  }
}
