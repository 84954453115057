.plug-dashbord-top-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: -10px;
}
.plug-dashboard-page .plug-dashboard-label {
  display: flex;
  width: 20%;
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 700;
  color: #323c4d;
  padding-left: 3px;
}

.plug-dashboard-page .button-container {
  display: flex;
}

.plug-dashboard-page .device-log-button {
  background-color: #fff !important;
  border: 1px solid #f0f2f4 !important;
  border-color: none !important;
  color: #606c80 !important;
}
.plug-dashboard-page .plug-readings {
  display: flex !important;
  justify-content: space-between;
}

.plug-dashboard-page .status-div {
  background-color: #fafafa;
  height: 45px;
}

.plug-dashboard-page .border-right-div {
  border-right: 1px solid #e9e9e9 !important;
  height: 20px;
}
.plug-dashboard-page .voltage-text-div {
  color: #f39200;
  font-size: 16px;
  font-weight: 900;
  padding-right: 20px;
}
.plug-dashboard-page .test {
  display: flex !important;
  background-color: #fafafa !important;
  height: 45px;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin-top: 15px;
}
.plug-dashboard-page .voltage-label-div {
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 800;
  padding-left: 15px;
}
.plug-dashboard-page .status-div {
  display: flex !important;
  height: 45px;
  justify-content: space-between;
  align-items: center;
  background-color: transparent !important;
  gap: 10px;
  margin-top: 15px;
}
.plug-dashboard-page .status-on-btn {
  background-color: rgb(77, 207, 113);
  margin-right: 17px;
  border-radius: 50px;
  width: 55px;
  height: 35px;
}
.plug-dashboard-page .status-off-btn {
  background-color: #f46f6f;
  margin-right: 28px;
  border-radius: 50px;
  width: 55px;
  height: 35px;
}
.plug-dashboard-page .status-div-label {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 7px;
}
.plug-dashboard-page .plug-dashboasrd-current-readings {
  margin-bottom: 28px;
}
.plug-dashboard-page .plug-dashboasrd-profile {
  max-height: 318px !important;
}
.testplug {
  display: flex;
  background-color: aqua;
}
.plug-dashboard-page .plug-dashboasrd-energy {
  margin-bottom: 30px;
}
.plug-dashboard-page .plug-dashboasrd-schedule {
  margin-left: 14px;
  padding: 0;
  height: 318px !important;
  overflow-y: scroll;
}

.plug-dashboard-page .main-plug {
  margin-bottom: 28px;
  max-height: 318px !important;
}

.plug-dashboard-page .profile-col {
  height: 318px !important;
}

.plug-dashboard-page .schedule-col {
  height: 100% !important;
  padding: 0;
}

.plug-dashboard-page .time-box {
  display: flex;
  justify-content: space-between;
}
.plug-dashboard-page .card-compponent {
  height: 100%;
}

.plug-dashboard-page .theme-card-component {
  height: 100%;
}

.plug-dashboard-page .profile-theme-card {
  max-height: 318px !important;
}

.plug-dashboard-page .schedule-theme-card {
  min-height: 300px;
}

.plug-dashboard-page .serial-no-label {
  color: #323c4d;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 3px;
}
.plug-dashboard-page .device-type-label {
  color: #323c4d;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 3px;
  /* padding-left: 8px; */
}
.plug-dashboard-page .serial-no-row-div {
  margin-top: 7px;
}
.plug-dashboard-page .serial-no-value {
  color: #323c4d;
  font-weight: 600;
  font-size: 16px;
  max-width: 150px;
  word-wrap: break-word;
}
.plug-dashboard-page .device-type-value {
  color: #323c4d;
  font-weight: 600;
  font-size: 16px;
  /* padding-left: 8px; */
}

.plug-dashboard-page .frequency-box {
  display: flex;
}
.plug-dashboard-page .frequency-update-btn-box {
  margin-top: 15px;
  margin-right: 5px;
}
.plug-dashboard-page .frequency-btn {
  margin-left: 10px !important;
  height: 30px !important;
  width: 64px !important;
  text-align: center;
  font-size: 14px;
  align-items: center;
  margin-top: 5px;
  display: flex;
  padding: 7px !important;
}
.plug-dashboard-page .schedule-label {
  color: #323c4d;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 3px;
}
.plug-dashboard-page .line-div {
  border-bottom: 1px solid #e9e9e9 !important;
  height: 20px;
}

.plug-dashboard-page .holidaymode-box {
  display: flex;
  justify-content: space-between !important;
}
.plug-dashboard-page .holidaymode-row-div {
  margin-top: 24px;
  height: 50px;
}
.plug-dashboard-page .holidaymode-start-date {
  color: #323c4d;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 3px;
}

.plug-dashboard-page .holidaymode-date-text {
  color: #323c4d;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 3px;
  text-align: right !important;
}
.plug-dashboard-page .holidaymode-time-text {
  color: #323c4d;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 3px;
  text-align: right !important;
}
.plug-dashboard-page .loop-timer-div {
  display: flex;
  justify-content: space-between !important;
}
.plug-dashboard-page .loop-mode-div {
  display: flex;
}
.plug-dashboard-page .loop-time-text {
  margin-right: 10px;
}
.plug-dashboard-page .off-timer-time {
  justify-content: center;
  align-items: center;
  border-radius: 53%;
  text-align: center;
  display: flex;
  height: 50px;
  width: 50px;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #323c4d;
  color: #323c4d;
  opacity: 0.2;
}

.plug-dashboard-page .active-time {
  background-color: #f5a833;
  border: 1px solid #f5a833;
  color: white;
  opacity: 1;
}

.plug-dashboard-page .inactive-time {
  opacity: 1;
}
.plug-dashboard-page .off-timer-main-div {
  display: flex;
  justify-content: space-between;
}

.plug-dashboard-page .off-timer-row-div {
  margin-top: 18px;
}
.plug-dashboard-page .remaining-time {
  color: #323c4d;
  font-weight: 600;
  font-size: 14px;
  margin-top: 2px;
}

.plug-dashboard-page .line-div-off-timer {
  border-bottom: 1px solid #e9e9e9 !important;
  width: 9px;
  margin-bottom: 23px;
}

.scrolBarStyle::-webkit-scrollbar-track {
  background-color: hsl(0, 0%, 95%);
  overflow-x: hidden;
}

.scrolBarStyle::-webkit-scrollbar {
  width: 6px;
  border-radius: 5px;
  background-color: #f3f3f3;
}

.scrolBarStyle::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border: 0;
  height: 5px;
}

.plug-dashboard-page .date-picker {
  width: 25px;

}
.plug-dashboard-page .datepicker-Row {
  flex-direction: row-reverse;
  margin-top: 20px;
}

.plug-dashboard-page .current-reading-row {
  margin-top: 5px;
}

.modal-export-pdf-data .date-range {
  display: flex;
  height: 48px;
}
.modal-export-pdf-data .date-range-label {
  color: #606c80;
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
  padding-top: 10px;
}
.modal-export-pdf-data .picker-date .daterange-picker {
  position: absolute;
  width: 64%;
  margin-top: 10px;
  z-index: 9;
}

.plug-dashboard-page .schedule-theme-card .card-compponent {
  height: auto;
  min-height: 318px;
}
.plug-dashboard-page .plug-legend .legend-item-true {
  padding: 7px;
}
.plug-dashboard-page .plug-legend .legend-color {
  width: 15px;
  height: 15px;
}
.plug-dashboard-page .plug-legend .legend-item-false {
  padding: 7px;
}
.plug-dashboard-page .schedule-box {
  border-bottom: 1px solid #f0f2f4 !important;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.current-reading-row .current-readings {
  width: 240px !important;
}
.plug-dashboard-page .plug-graph-title {
  display: none;
}
.plug-dashboard-page .daterange-picker {
  width: 255px;
}
.plug-dashboard-page .schedule-empty-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-top: 26px;
}
.plug-dashboard-page .empty-box-text {
  color: #606c80;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.plug-dashboard-page .off-timer-empty-div {
  display: flex;
  justify-content: space-between;
  opacity: 15%;
}
.plug-dashboard-page .loop-timer-empty-div {
  display: flex;
  justify-content: space-between !important;
  opacity: 15%;
}
.plug-dashboard-page
  .plug-dashboasrd-holiday-mode
  .theme-card-component
  .card-compponent {
  padding: 30px 30px 10px 30px !important;
}

.plug-dashboard-page .datepicker-with-title {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.plug-dashboard-page .graph-title-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  

}
.plug-dashboard-page .chart-card .card-compponent {
  padding-top: 20px;
}
.plug-dashboard-page .status-error-btn {
  background-color: #929292;
  margin-right: 28px;
  border-radius: 50px;
  width: 65px;
  height: 35px;
}

.plug-dashboard-page .tittle-card-graph {
  color: #f39200;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0px 8px 10px;
  

}

.plug-dashboard-page .line-div-off-timer:last-child {
  display: none;
}
.plug-dashboard-page .settings-button {
  background-color: #ffffff !important;
  border: 1px solid #f0f2f4 !important;
  border-radius: 5px;
  padding: 10px;
  width: 50px;
}
.plug-dashboard-page .settings-button img {
  margin-left: 0px !important;
}

.plug-dashboard-page .date-picker {
  width: 255px;
}
.plug-dashboard-page .alarm-box {
  margin-top: 15px;
}
.plug-dashboard-page .alarm-label {
  color: #323c4d;
  font-weight: 300;
  font-size: 14px;
}
.plug-dashboard-page .alarm-text {
  color: #323c4d;
  font-weight: 600;
  font-size: 14px;
  min-width: 230px;
}
.plug-dashboard-page .alarm-text img {
  cursor: pointer;
  margin-left: 5px;
}
.plug-dashboard-page .data-unavailable-placeholder {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 100px;
}
.plug-dashboard-page .data-unavailable-placeholder div {
  font-weight: 700;
  font-size: 16px;
  color: #323c4d;
}
/* new change */
.plug-dashboard-page .information-icon {
  float: right;
  cursor: pointer;
  padding: 0px 0px 8px 10px;
}
.plug-dashboard-page .badge-modal .command-badge-modal-width {
  width: 380px;
}
.plug-dashboard-page .calculation-description{
  padding-top: 15px;
  font-size: 15px;
}
.plug-dashboard-page .badge-modal .colored-button {
  display: none;
}
.plug-dashboard-page .badge-modal .outlined-button {
  width: 320px;
}