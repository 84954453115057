.theme-card-component .card-compponent {
  width: 100%;
  padding: 30px;
  background: white;
  border: 1px solid #f0f2f4;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px rgba(96, 108, 128, 0.05);
}

.theme-card-component .card-title {
  color: #f39200;
  font-size: 16px;
  font-weight: 600;
}
