.status-device-list-page {
  display: flex;
}
.status-device-list-page .view-column-List {
  display: flex;
  gap: 20px;
  cursor: pointer;
}
.status-device-list-page .notify-button {
  background-color: rgba(243, 146, 0, 0.15);
  border: none;
  color: #f39200;
  width: 70px !important;
  height: 25px;

  border-radius: 2px;
}
.status-device-list-page .status-List-device {
  margin-top: 30px;
}
.status-device-list-page .hide-notify-btn tbody tr td:nth-child(5) {
  display: none;
}
.status-device-list-page .hide-notify-btn thead tr th:nth-child(5) {
  display: none;
}

.status-device-list-page .phonenumber-style-devicelist {
  color: #848d9b;
  font-size: 12px;
  font-weight: 400;
}

.status-device-list-page .title-none {
  display: none;
}
.status-device-list-page .pagination-mt {
  margin-top: 30px;
}
.status-device-list-page .device-status-heading {
  text-transform: capitalize;
}
.status-device-list-page .empty-devicelist td {
  color: red;
  overflow: hidden;
}
.status-device-list-page .alarm-status {
  width: 230px;
}
.status-device-list-page .alarm-status img {
  margin-left: 5px;
  width: 20px;
  cursor: pointer;
}
.status-device-list-page .export-graph-button {
  float: right;
}
.status-device-list-page .download-data{
  display: flex;
  gap: 20px;
  float: right;
}
.status-device-list-page .refresh-button-label {
  color: #f39200;
  text-align: left;
  display: flex;
}

.status-device-list-page .settings-button img {
  margin-left: 0px !important;
}
.status-device-list-page .settings-button {
  background-color: #ffffff !important;
  border: 1px solid #f0f2f4 !important;
  border-radius: 5px;
  padding: 10px;
  width: 50px;
}
.status-device-list-page .refresh-text{
  margin-top: 10px;
  margin-bottom: 0px !important;
}