.reset-password-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password-page .margin-top {
  margin-top: 40px;
}
.error-msg-reset {
  color:#f46f6f !important
 
}
.newpasswordInput{
  position: relative;
}
.eye-Icon-Vsisble-reset{
  position: relative;
}
i.bi.bi-eye-slash {
  position: absolute;
  right: 0;
  bottom: 10px;
  padding: 0px 13px !important;
}