li.breadcrumb-item {
  list-style: none;
  margin-left: 67px;
}
.breadcrumb-label {
  font-size: 14px;
  font-weight: 500;
  color: #606c80;
  margin-right: 10px;
  padding-left: 6px;
}
.breadcrumb-item .icon-nav-bar {
  margin: 0;
}
li.breadcrumb-item:nth-child(2)::after {
  content: " →"; /* Add a space before the arrow symbol if needed */
  margin-right: 9x;
}

.breadcrumb-item .right-arrow {
  width: 7px;
  margin-right: 7px;
}
