.plug-power-status-main .download-data {
  width: 170px !important;
}
.plug-power-status-main .legend-item {
  border: none;
  cursor: default;
}
.plug-power-status-main .status-heading-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: -10px;
}
.plug-power-status-main .status-data-tilte {
  color: #323c4d;
  font-size: 20px;
  font-weight: 700;
}
.plug-power-status-main .power-status-graph-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.plug-power-status-main .power-status-heading {
  color: #f39200;
  font-weight: 600;
  font-size: 16px;
}
.plug-power-status-main .status-text {
  color: #4f4f4f;
  font-size: 12px;
  font-weight: 400;
}
.plug-power-status-main .status-hours {
  color: #323c4d;
  font-size: 12px;
  font-weight: 700;
}
.plug-power-status-main .date-picker {
  width: 255px;
}
.plug-power-status-main .daterange-picker {
  position: absolute;
  width: 255px;
  margin-top: -25px;
  z-index: 9;
}
.power-status-chart {
  position: relative;
}
.yaxis-hours-label {
  position: absolute;
  margin-top: -200px;
  transform: rotate(-90deg);
  left: -15px;
  color: #4f4f4f;
  font-weight: 400;
  font-size: 14px;
}
.plug-power-status-main .power-status-card-title {
  display: none;
}
.plug-power-status-main  .legend-device-power{
  padding: 30px 10px 10px 10px;
}
/* newchanges */
.plug-power-status-main .information-icon {
  float: right;
  cursor: pointer;
  padding: 0px 0px 8px 10px;
}
.plug-power-status-main .badge-modal .command-badge-modal-width {
  width: 380px;
}
.plug-power-status-main .calculation-description{
  padding-top: 15px;
  font-size: 15px;
}
.plug-power-status-main .badge-modal .colored-button {
  display: none;
}
.plug-power-status-main .badge-modal .outlined-button {
  width: 320px;
}